<template>
    <div class="layout-body">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->
        <!--s: Data-->
        <div class="product-prompt">
            <div class="product-data">
                <span class="name">{{ monthValue!==''?monthValue:'MM / YYYY' }}</span>
                <DatePicker type="month" @on-change="onMonthValue" format="MM-yyyy"></DatePicker>
            </div>
<!--            <div class="product-data">-->
<!--                <span class="name">{{ 'Week '+weekValue }}</span>-->
<!--            </div>-->
        </div>
        <!--e: Data-->

        <!--s: Launch -->
        <div class="launch-body">

            <!--s: table-->
            <div class="launch-table">
                <div class="launch-top">
                    <div class="launch-tab">
                        <a href="javascript:" :class="edmParams.emailAutoValue === welcome.value?'active':''" :key="key" v-for="(welcome,key) in welcomeList">{{ welcome.label }}</a>
                    </div>
<!--                    <a href="javascript:" class="launch-tab-add" @click="onAddList">-->
<!--                        <Icon custom="launch-add"></Icon>-->
<!--                    </a>-->
                </div>
                <div class="launch-content">
                    <Table max-height="350" :loading="emailAutoLoading"  :columns="welcomeColumns" :data="emailAutoArray" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectAllCancel" @on-select="onSelect" @on-select-cancel="onSelectCancel">
                        <template slot="startTime" slot-scope="{ row }">
                            {{ formatUnit(row.startTime) }}
                        </template>
                        <template slot-scope="{ row }" slot="status">
                            <Dropdown @on-click="onSelectItem($event,row.id)">
                                <a href="javascript:void(0)" class="select-item">
                                    {{ row.status === 1?'Random':'' }}
                                    {{ row.status === 2?'On':'' }}
                                    {{ row.status === 3?'Pause':'' }}
                                    <Icon type="ios-arrow-down"></Icon>
                                </a>
                                <DropdownMenu slot="list">
                                    <DropdownItem name="1" :selected="row.status === 1">Random</DropdownItem>
                                    <DropdownItem name="2" :selected="row.status === 2">On</DropdownItem>
                                    <DropdownItem name="3" :selected="row.status === 3">Pause</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </template>
                        <template slot="btn" slot-scope="{ row }">
                            <a href="javascript:" class="btn" @click="onEditBanner(row)">Edit</a>
                        </template>
                    </Table>
                    <div class="launch-btn">
                        <div class="box">
                            <div class="pint" v-if="emailAutoStatus">
                                <span class="title">Sure delete?</span>
                                <Button style="width:71px" :loading="btnStatus" @click="onDelEmailAutoList">Yes</Button>
                                <Button @click="emailAutoStatus = false">Cancel</Button>
                            </div>
                            <Button @click="onEmailAutoDelete">Delete</Button>
                        </div>
                    </div>
                </div>
            </div>
            <!--e: table-->

            <!--s: table-->
            <div class="launch-table">
                <div class="launch-type">
                    <a href="javascript:" :class="edmParams.type === item.id?'active':''" :key="key" v-for="(item,key) in classification" @click="onTypeSelect(item.id)">{{ item.name }}</a>
                </div>
                <div class="launch-top">
                    <div class="launch-tab">
                        <a href="javascript:" :class="edmParams.edmValue === edmType.value?'active':''" :key="key" v-for="(edmType,key) in edmTypeList" @click="onSelectEdm(edmType.value)">{{ edmType.label }}</a>
                    </div>
                    <a href="javascript:" class="launch-tab-add" @click="onAddContentList">
                        <Icon custom="launch-add"></Icon>
                    </a>
                </div>
                <div class="launch-content">
                    <Table max-height="350" :loading="edmLoading"  :columns="edmColumns" :data="edmArray" @on-select-all="onSelectContentAll" @on-select-all-cancel="onSelectContentAllCancel" @on-select="onSelectContent" @on-select-cancel="onSelectContentCancel">
                        <template slot="sendTime" slot-scope="{ row }">
                          {{ formatUnit(row.sendTime) }}
                        </template>
                        <template slot-scope="{ row }" slot="status">
                            <Dropdown @on-click="onSelectEdmItem($event,row.id)">
                                <a href="javascript:void(0)" class="select-item">
                                    {{ row.status === 1?'Random':'' }}
                                    {{ row.status === 2?'On':'' }}
                                    {{ row.status === 3?'Pause':'' }}
                                    <Icon type="ios-arrow-down"></Icon>
                                </a>
                                <DropdownMenu slot="list">
                                    <DropdownItem name="1" :selected="row.status === 1">Random</DropdownItem>
                                    <DropdownItem name="2" :selected="row.status === 2">On</DropdownItem>
                                    <DropdownItem name="3" :selected="row.status === 3">Pause</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </template>
                        <template slot="btn" slot-scope="{ row }">
                            <a href="javascript:" class="btn" @click="onEditContent(row)">Edit</a>
                        </template>
                    </Table>
                    <div class="launch-btn">
                        <div class="box">
                            <div class="pint" v-if="contentStatus">
                                <span class="title">Sure delete?</span>
                                <Button style="width:71px" :loading="btnStatus" @click="onContentDeleteConfirm">Yes</Button>
                                <Button @click="contentStatus = false">Cancel</Button>
                            </div>
                            <Button @click="onContentDelete">Delete</Button>
                        </div>
                    </div>
                  </div>
            </div>
            <!--e: table-->

        </div>
        <!--e: Launch -->

    </div>
</template>
<script>
import EdmList from './list'
export default EdmList
</script>
<style lang="less">
@import "list";
</style>
