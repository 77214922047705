/**
 * edm列表
 */
import Vue from 'vue'
import { Message,Page,Input,DatePicker,locale,Table,Button,Icon,Dropdown,DropdownMenu,DropdownItem } from 'iview';
import lang from 'iview/dist/locale/en-US';
locale(lang)
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('DatePicker', DatePicker);
Vue.component('Table', Table);
Vue.component('Button', Button);
Vue.component('Icon', Icon);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
import { formatUnit } from '@/utils/tools'
import { welcomeList,welcomeColumns,edmColumns,edmTypeList,
    getEmailAutoListService,
    getEmailAutoEditStatusService,
    delEmailAutoListService,
    getEdmListService,
    getEdmEditStatusService,
    delEdmListService,
} from '@/service/edm-service'
import { classification } from "@/service/spu-group-service/index";
import Header from '@/components/Header/header.vue'
import { mapMutations } from "vuex";
const EdmList = {
    name:'EdmList',
    components:{
        Header
    },
    data() {
        return {
            formatUnit:formatUnit,
            monthValue:'', //年月值
            weekValue:'', //周值
            welcomeList:welcomeList,//欢迎 thead 数据集
            edmTypeList:edmTypeList,//edm 分类 thead 数据集
            classification:classification, //分类
            selectAllCancel:[], //全选时的临时存储
            selectAllContentCancel:[], //全选时的临时存储
            emailAutoLoading:false, //banner loading
            edmLoading:false, //edm loading
            emailAutoStatus:false,
            contentStatus:false,
            btnStatus:false,
            welcomeColumns:welcomeColumns, //欢迎表头
            edmColumns:edmColumns, //欢迎表头
            emailAutoArray:[], //reply数据集
            edmArray:[], //edm数据集
            edmParams:{  //选中参数
                emailAutoValue:1,
                edmValue:5,
                type:1,
                checkInput:[],//选择的banner数据列表项
                checkContentInput:[],//选择的数据列表项
            },
        }
    },
    created() {
        this.onEmailAutoList().then()
        this.SET_TYPE_ID('1')
        this.onEdmList().then()
    },
    methods: {
        ...mapMutations('launch',['SET_LAUNCH_ID','SET_LAUNCH_NAME','SET_LAUNCH_TITLE','SET_TYPE_ID']),
        /**
         * 点击全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectAll(selection) {
            this.selectAllCancel = selection
            for(let key in selection){
                let flag = true
                for(let index in this.edmParams.checkInput){
                    if(selection[key].id === this.edmParams.checkInput[index]){
                        flag = false
                    }
                }
                if(flag){
                    this.edmParams.checkInput.push(selection[key].id)
                }
            }
        },
        /**
         * 点击取消全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectAllCancel() {
            let selection = this.selectAllCancel
            for(let key in selection){
                for(let index in this.edmParams.checkInput){
                    if(selection[key].id === this.edmParams.checkInput[index]){
                        this.edmParams.checkInput.splice(index,1);
                    }
                }
            }
        },
        /**
         * 选中某以一项时触发
         * selection: 以选项数据
         * row: 刚选择的数据
         * some() 方法用于检测数组中的元素是否满足指定条件
         * 注意： some() 不会对空数组进行检测。
         * 注意： some() 不会改变原始数组。
         * @param selection，row
         */
        onSelect(selection,row) {
            let mediaList = this.edmParams.checkInput.some((e)=>{
                return e.id === row.id
            })
            if(!mediaList){
                this.edmParams.checkInput.push(row.id)
            }
        },
        /**
         * 取消选中某一项时触发
         * selection: 以选项数据
         * row: 取消选择的项数据
         * 遍历当前选中数组 如有相同则在当前数组的指定位置进行删除
         * @param selection，row
         */
        onSelectCancel(selection,row) {
            for(let key in this.edmParams.checkInput){
                if(this.edmParams.checkInput[key] === row.id){
                    this.edmParams.checkInput.splice(key,1);
                }
            }
        },
        /**
         * 点击全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectContentAll(selection) {
            this.selectAllContentCancel = selection
            for(let key in selection){
                let flag = true
                for(let index in this.edmParams.checkContentInput){
                    if(selection[key].id === this.edmParams.checkContentInput[index]){
                        flag = false
                    }
                }
                if(flag){
                    this.edmParams.checkContentInput.push(selection[key].id)
                }
            }
        },
        /**
         * 点击取消全选时触发
         * selection: 以选项数据
         * @param selection
         */
        onSelectContentAllCancel() {
            let selection = this.selectAllContentCancel
            for(let key in selection){
                for(let index in this.edmParams.checkContentInput){
                    if(selection[key].id === this.edmParams.checkContentInput[index]){
                        this.edmParams.checkContentInput.splice(index,1);
                    }
                }
            }
        },
        /**
         * 选中某以一项时触发
         * selection: 以选项数据
         * row: 刚选择的数据
         * some() 方法用于检测数组中的元素是否满足指定条件
         * 注意： some() 不会对空数组进行检测。
         * 注意： some() 不会改变原始数组。
         * @param selection，row
         */
        onSelectContent(selection,row) {
            let mediaList = this.edmParams.checkContentInput.some((e)=>{
                return e.id === row.id
            })
            if(!mediaList){
                this.edmParams.checkContentInput.push(row.id)
            }
        },
        /**
         * 取消选中某一项时触发
         * selection: 以选项数据
         * row: 取消选择的项数据
         * 遍历当前选中数组 如有相同则在当前数组的指定位置进行删除
         * @param selection，row
         */
        onSelectContentCancel(selection,row) {
            for(let key in this.edmParams.checkContentInput){
                if(this.edmParams.checkContentInput[key] === row.id){
                    this.edmParams.checkContentInput.splice(key,1);
                }
            }
        },
        /**
         * 修改状态
         * @param value
         * @param id
         */
        onSelectItem(value,id) {
            this.onEmailAutoEditStatus(value,id).then()
        },
        /**
         * 修改edm状态
         * @param value
         * @param id
         */
        onSelectEdmItem(value,id) {
            this.onEdmEditStatus(value,id).then()
        },
        /**
         * 删除auto数据
         */
        onEmailAutoDelete() {

            if(this.edmParams.checkInput.length === 0){
                Message.error('Please select')
            }else{
                this.emailAutoStatus = true;
            }
        },
        /**
         * 删除edm数据
         */
        onContentDelete(){
            if(this.edmParams.checkContentInput.length === 0){
                Message.error('Please select')
            }else{
                this.contentStatus = true;
            }
        },
        /**
         * 删除edm数据确认
         */
        onContentDeleteConfirm() {
            if(this.edmParams.checkContentInput.length === 0){
                Message.error('Please select');
                return;
            }
            this.onDelEdmList().then()
        },
        /**
         * 返回至列表页
         */
        onBack() {
            this.$router.back()
        },
        /**
         * 选择年月
         * @param month
         */
        onMonthValue(month){
            this.monthValue = month
        },
        /**
         * 选择周
         * @param week
         */
        onWeekValue(week) {
            this.weekValue = week
        },
        /**
         * 添加数据
         */
        onAddContentList(){
            if(this.edmParams.edmValue === 5){
                this.SET_LAUNCH_NAME('EDM A')
            }
            if(this.edmParams.edmValue === 6){
                this.SET_LAUNCH_NAME('EDM B')
            }
            if(this.edmParams.edmValue === 7){
                this.SET_LAUNCH_NAME('EDM C')
            }
            this.SET_LAUNCH_TITLE('reply')
            this.SET_LAUNCH_ID('')
            this.$router.push('/edm/edit')
        },
        /**
         * 跳转编辑
         * @param row
         */
        onEditBanner(row) {
            this.SET_LAUNCH_NAME(row.emailTitle)
            if(row.type === 1){
                this.SET_LAUNCH_TITLE('welcome')
            }else{
                this.SET_LAUNCH_TITLE('reply')
            }
            this.SET_LAUNCH_ID(row.id)
            this.$router.push('/edm/reply/edit')
        },
        /**
         * 跳转编辑
         * @param id
         */
        onEditContent(row) {
            this.SET_LAUNCH_TITLE(row.type)
            if(row.type === 5){
                this.SET_LAUNCH_NAME('EDM A')
            }
            if(row.type === 6){
                this.SET_LAUNCH_NAME('EDM B')
            }
            if(row.type === 7){
                this.SET_LAUNCH_NAME('EDM C')
            }
            this.SET_LAUNCH_ID(row.id)
            this.$router.push('/edm/edit')
        },
        /**
         * 选择分类id
         * @param id
         */
        onTypeSelect(id){
            this.edmParams.type = id
            this.SET_TYPE_ID(id)
            this.onEdmList().then()
        },
        /**
         * 选择edm
         * @param value
         */
        onSelectEdm(value) {
            this.edmParams.edmValue = value
            this.edmParams.checkContentInput = []
            this.onEdmList().then()
        },
        /**
         * 获取欢迎列表
         * @returns {Promise<void>}
         */
        async onEmailAutoList() {
            try {
                this.emailAutoLoading = true
                let params = {
                    'type':this.edmParams.emailAutoValue,
                }
                const {code,data} = await getEmailAutoListService(params)
                if(code === 1){
                    this.emailAutoArray = data
                }
                this.emailAutoLoading = false
            }catch (error){
                this.emailAutoLoading = false
                console.log(error)
            }
        },
        /**
         * 修改欢迎列表状态
         * @returns {Promise<void>}
         */
        async onEmailAutoEditStatus(status,id) {
            try {
                let params = {
                    id:id,
                    status:status,
                }
                const {code} = await getEmailAutoEditStatusService(params)
                if(code === 1){
                    this.onEmailAutoList().then()
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除欢迎列表数据
         * @returns {Promise<void>}
         */
        async onDelEmailAutoList() {
            try {
                if(this.edmParams.checkInput.length === 0){
                    Message.error('Please select');
                    return;
                }
                this.btnStatus = true
                let params = {
                    'ids':this.edmParams.checkInput,
                }
                const {code} = await delEmailAutoListService(params)
                if(code === 1){
                    Message.success('successful')
                    this.onEmailAutoList().then()
                    this.edmParams.checkInput = []
                    this.emailAutoStatus = false
                }
                this.btnStatus = false
            }catch (error){
                this.btnStatus = false
                console.log(error)
            }
        },
        /**
         * 获取edm列表
         * @returns {Promise<void>}
         */
        async onEdmList() {
            try {
                this.edmLoading = true
                let params = {
                    'type':this.edmParams.edmValue,
                    cid1:this.edmParams.type
                }
                const {code,data} = await getEdmListService(params)
                if(code === 1){
                    this.edmArray = data
                }
                this.edmLoading = false
            }catch (error){
                this.edmLoading = false
                console.log(error)
            }
        },

        /**
         * 修改edm列表状态
         * @returns {Promise<void>}
         */
        async onEdmEditStatus(status,id) {
            try {
                let params = {
                    id:id,
                    status:status,
                }
                const {code} = await getEdmEditStatusService(params)
                if(code === 1){
                    this.onEdmList().then()
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除edm列表数据
         * @returns {Promise<void>}
         */
        async onDelEdmList() {
            try {
                if(this.edmParams.checkContentInput.length === 0){
                    Message.error('Please select');
                    return;
                }
                this.btnStatus = true
                let params = {
                    'ids':this.edmParams.checkContentInput,
                }
                const {code} = await delEdmListService(params)
                if(code === 1){
                    Message.success('successful')
                    this.onEdmList().then()
                    this.edmParams.checkContentInput = []
                    this.contentStatus = false
                }
                this.btnStatus = false
            }catch (error){
                this.btnStatus = false
                console.log(error)
            }
        },
    },
}
export default EdmList
