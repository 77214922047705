var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-body"},[_c('Header'),_c('div',{staticClass:"product-type"},[_c('div',{staticClass:"type-back"},[_c('a',{attrs:{"href":"javascript:"},on:{"click":_vm.onBack}},[_vm._v("Back")])])]),_c('div',{staticClass:"product-prompt"},[_c('div',{staticClass:"product-data"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.monthValue!==''?_vm.monthValue:'MM / YYYY'))]),_c('DatePicker',{attrs:{"type":"month","format":"MM-yyyy"},on:{"on-change":_vm.onMonthValue}})],1)]),_c('div',{staticClass:"launch-body"},[_c('div',{staticClass:"launch-table"},[_c('div',{staticClass:"launch-top"},[_c('div',{staticClass:"launch-tab"},_vm._l((_vm.welcomeList),function(welcome,key){return _c('a',{key:key,class:_vm.edmParams.emailAutoValue === welcome.value?'active':'',attrs:{"href":"javascript:"}},[_vm._v(_vm._s(welcome.label))])}),0)]),_c('div',{staticClass:"launch-content"},[_c('Table',{attrs:{"max-height":"350","loading":_vm.emailAutoLoading,"columns":_vm.welcomeColumns,"data":_vm.emailAutoArray},on:{"on-select-all":_vm.onSelectAll,"on-select-all-cancel":_vm.onSelectAllCancel,"on-select":_vm.onSelect,"on-select-cancel":_vm.onSelectCancel},scopedSlots:_vm._u([{key:"startTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatUnit(row.startTime))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('Dropdown',{on:{"on-click":function($event){return _vm.onSelectItem($event,row.id)}}},[_c('a',{staticClass:"select-item",attrs:{"href":"javascript:void(0)"}},[_vm._v(" "+_vm._s(row.status === 1?'Random':'')+" "+_vm._s(row.status === 2?'On':'')+" "+_vm._s(row.status === 3?'Pause':'')+" "),_c('Icon',{attrs:{"type":"ios-arrow-down"}})],1),_c('DropdownMenu',{attrs:{"slot":"list"},slot:"list"},[_c('DropdownItem',{attrs:{"name":"1","selected":row.status === 1}},[_vm._v("Random")]),_c('DropdownItem',{attrs:{"name":"2","selected":row.status === 2}},[_vm._v("On")]),_c('DropdownItem',{attrs:{"name":"3","selected":row.status === 3}},[_vm._v("Pause")])],1)],1)]}},{key:"btn",fn:function(ref){
var row = ref.row;
return [_c('a',{staticClass:"btn",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.onEditBanner(row)}}},[_vm._v("Edit")])]}}])}),_c('div',{staticClass:"launch-btn"},[_c('div',{staticClass:"box"},[(_vm.emailAutoStatus)?_c('div',{staticClass:"pint"},[_c('span',{staticClass:"title"},[_vm._v("Sure delete?")]),_c('Button',{staticStyle:{"width":"71px"},attrs:{"loading":_vm.btnStatus},on:{"click":_vm.onDelEmailAutoList}},[_vm._v("Yes")]),_c('Button',{on:{"click":function($event){_vm.emailAutoStatus = false}}},[_vm._v("Cancel")])],1):_vm._e(),_c('Button',{on:{"click":_vm.onEmailAutoDelete}},[_vm._v("Delete")])],1)])],1)]),_c('div',{staticClass:"launch-table"},[_c('div',{staticClass:"launch-type"},_vm._l((_vm.classification),function(item,key){return _c('a',{key:key,class:_vm.edmParams.type === item.id?'active':'',attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.onTypeSelect(item.id)}}},[_vm._v(_vm._s(item.name))])}),0),_c('div',{staticClass:"launch-top"},[_c('div',{staticClass:"launch-tab"},_vm._l((_vm.edmTypeList),function(edmType,key){return _c('a',{key:key,class:_vm.edmParams.edmValue === edmType.value?'active':'',attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.onSelectEdm(edmType.value)}}},[_vm._v(_vm._s(edmType.label))])}),0),_c('a',{staticClass:"launch-tab-add",attrs:{"href":"javascript:"},on:{"click":_vm.onAddContentList}},[_c('Icon',{attrs:{"custom":"launch-add"}})],1)]),_c('div',{staticClass:"launch-content"},[_c('Table',{attrs:{"max-height":"350","loading":_vm.edmLoading,"columns":_vm.edmColumns,"data":_vm.edmArray},on:{"on-select-all":_vm.onSelectContentAll,"on-select-all-cancel":_vm.onSelectContentAllCancel,"on-select":_vm.onSelectContent,"on-select-cancel":_vm.onSelectContentCancel},scopedSlots:_vm._u([{key:"sendTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatUnit(row.sendTime))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('Dropdown',{on:{"on-click":function($event){return _vm.onSelectEdmItem($event,row.id)}}},[_c('a',{staticClass:"select-item",attrs:{"href":"javascript:void(0)"}},[_vm._v(" "+_vm._s(row.status === 1?'Random':'')+" "+_vm._s(row.status === 2?'On':'')+" "+_vm._s(row.status === 3?'Pause':'')+" "),_c('Icon',{attrs:{"type":"ios-arrow-down"}})],1),_c('DropdownMenu',{attrs:{"slot":"list"},slot:"list"},[_c('DropdownItem',{attrs:{"name":"1","selected":row.status === 1}},[_vm._v("Random")]),_c('DropdownItem',{attrs:{"name":"2","selected":row.status === 2}},[_vm._v("On")]),_c('DropdownItem',{attrs:{"name":"3","selected":row.status === 3}},[_vm._v("Pause")])],1)],1)]}},{key:"btn",fn:function(ref){
var row = ref.row;
return [_c('a',{staticClass:"btn",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.onEditContent(row)}}},[_vm._v("Edit")])]}}])}),_c('div',{staticClass:"launch-btn"},[_c('div',{staticClass:"box"},[(_vm.contentStatus)?_c('div',{staticClass:"pint"},[_c('span',{staticClass:"title"},[_vm._v("Sure delete?")]),_c('Button',{staticStyle:{"width":"71px"},attrs:{"loading":_vm.btnStatus},on:{"click":_vm.onContentDeleteConfirm}},[_vm._v("Yes")]),_c('Button',{on:{"click":function($event){_vm.contentStatus = false}}},[_vm._v("Cancel")])],1):_vm._e(),_c('Button',{on:{"click":_vm.onContentDelete}},[_vm._v("Delete")])],1)])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }